.meta {
  font-size: 70%;
}

.contentTitle {
  font-style: italic;
  font-size: 80%;
}

.comment {
  font-size: 80%;
}
