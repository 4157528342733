.widget {
  margin: 10px 0;
}

.title {
  color: #ffffff;
  background-color: #283655;
  margin: 0;
  padding: 0 10px;
}

.content {
  margin: 0;
  padding: 0 10px;
}
