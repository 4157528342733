.ContentListItem {
  margin: 10px 0;
}

.title {
  color: #ffffff;
  background-color: #283655;
  margin: 0;
  padding: 0 10px;
}

.meta {
  color: #000000;
  background-color: #d0e1f9;
  margin: 0;
  padding: 0 10px;
}

.content {
  margin: 0;
  padding: 0 10px;
}
