.featureCard {
  font-size: smaller;
  margin: 0.5rem;
  max-width: 350px;
}

.featureCard p {
  margin: 0;
  padding: 0;
}

.featureCard img {
  display: block;
  width: 100%;
}

.featureCardBody {
  padding: 0.5rem;
}
